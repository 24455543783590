import { v1 } from "uuid";
import { GA4_PURCHASE, GA4_ADD_PAYMENT_INFO, GA4_SHARE } from "./helpers";
import analytics from "../../lib/analytics";

export const logGa4Ecommerce = (data) => {
  const uuid = v1();
  const { amount, currency, recurring, category, pageType } = data;

  const baseParams = {
    value: amount,
    currency,
    items: [
      {
        item_name: recurring ? "recurring" : "one-time",
        item_category: pageType,
      },
    ],
  };

  const purchase = {
    ...baseParams,
    transaction_id: uuid,
  };

  const paymentInfo = {
    payment_type: category,
    ...baseParams,
  };

  analytics.track(GA4_PURCHASE, purchase);
  analytics.track(GA4_ADD_PAYMENT_INFO, paymentInfo);
};

export const logShareToGA = (shareType) => {
  analytics.track(GA4_SHARE, {
    content_type: "link",
    method: getShareType(shareType),
  });
};

const getShareType = (shareType) => {
  switch (shareType) {
    case "f":
      return "facebook";
    case "t":
      return "twitter";
    case "e":
      return "email";
    case "w":
      return "whatsapp";
    default:
      return "other";
  }
};

export const measurementId = "G-PMEW5CDHC1";
