import { createSelector } from "reselect";

const initialState = {
  id: null,
  slug: null,
  title: null,
  queryParams: {},
  isMobile: false,
  followUpTemplate: null,
  supportedFollowUpTemplateTitles: [
    "Post Donate Page With Recurring Ask",
    "Post-donate page with recurring ask",
    "Post Donation Share",
  ],
  postAction: {
    copy: null,
    defaultImageUrl:
      "https://storage.googleapis.com/fa-assets/sou/sou-post-donate-bg.jpg",
    image: null,
  },
  templateName: "",
  forcedScrollToDonate: false,
  mintHeader: false,
  donationAskBody: null,
  currentView: "default",
  language: "en",
};

export const pageReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_QUERY_PARAMS: {
      return { ...state, queryParams: action.payload };
    }
    case SET_IS_MOBILE: {
      return { ...state, isMobile: action.payload };
    }
    case SET_PAGE_ID: {
      return { ...state, id: action.payload };
    }
    case SET_PAGE_SLUG: {
      return { ...state, slug: action.payload };
    }
    case SET_PAGE_TITLE: {
      return { ...state, title: action.payload };
    }
    case SET_FOLLOW_UP_TEMPLATE: {
      return { ...state, followUpTemplate: action.payload };
    }
    case SET_POST_ACTION_COPY: {
      return {
        ...state,
        postAction: { ...state.postAction, copy: action.payload },
      };
    }
    case SET_POST_ACTION_IMAGE: {
      return {
        ...state,
        postAction: { ...state.postAction, image: action.payload },
      };
    }
    case RESET_POST_ACTION_COPY: {
      return { ...state, postAction: { ...state.postAction, copy: null } };
    }
    case RESET_POST_ACTION_IMAGE: {
      return { ...state, postAction: { ...state.postAction, image: null } };
    }
    case SET_TEMPLATE_NAME: {
      return { ...state, templateName: action.payload };
    }
    case SET_FORCED_SCROLL_TO_DONATE_TEMPLATE: {
      return { ...state, forcedScrollToDonate: action.payload };
    }
    case SET_MINT_HEADER: {
      return { ...state, mintHeader: action.payload };
    }
    case SET_LANGUAGE: {
      return { ...state, language: action.payload };
    }
    case SET_CURRENT_VIEW: {
      return { ...state, currentView: action.payload };
    }
    case SET_DONATION_ASK_BODY: {
      // Checks if the string is not empty or contains only spaces
      if (action.payload?.trim().length > 0) {
        return { ...state, donationAskBody: action.payload };
      } else {
        return { ...state };
      }
    }
    default:
      return state;
  }
};

// action creators
export const setPageId = (id) => {
  return {
    type: SET_PAGE_ID,
    payload: id,
  };
};

export const setPageSlug = (slug) => {
  return {
    type: SET_PAGE_SLUG,
    payload: slug,
  };
};

export const setPageTitle = (title) => {
  return {
    type: SET_PAGE_TITLE,
    payload: title,
  };
};

export const setQueryParams = (params) => {
  return {
    type: SET_QUERY_PARAMS,
    payload: params,
  };
};

export const setIsMobile = (params) => {
  return {
    type: SET_IS_MOBILE,
    payload: params,
  };
};

export const setFollowUpTemplate = (params) => {
  return {
    type: SET_FOLLOW_UP_TEMPLATE,
    payload: params,
  };
};

export const setPostActionCopy = (params) => {
  return {
    type: SET_POST_ACTION_COPY,
    payload: params,
  };
};

export const setPostActionImage = (params) => {
  return {
    type: SET_POST_ACTION_IMAGE,
    payload: params,
  };
};

export const resetPostActionCopy = (params) => {
  return {
    type: RESET_POST_ACTION_COPY,
    payload: params,
  };
};

export const resetPostActionImage = (params) => {
  return {
    type: RESET_POST_ACTION_IMAGE,
    payload: params,
  };
};

export const setTemplateName = (params) => {
  return {
    type: SET_TEMPLATE_NAME,
    payload: params,
  };
};

export const setForcedScrollToDonate = (params) => {
  return {
    type: SET_FORCED_SCROLL_TO_DONATE_TEMPLATE,
    payload: params,
  };
};

export const setMintHeader = (params) => {
  return {
    type: SET_MINT_HEADER,
    payload: params,
  };
};

export const setCurrentView = (params) => {
  return {
    type: SET_CURRENT_VIEW,
    payload: params,
  };
};

export const setLanguage = (params) => {
  return {
    type: SET_LANGUAGE,
    payload: params,
  };
};

export const setDonationAskBody = (params) => {
  return {
    type: SET_DONATION_ASK_BODY,
    payload: params,
  };
};

// selectors
export const getPageId = (state) => state.page.id;
export const getPageSlug = (state) => state.page.slug;
export const getPageTitle = (state) => state.page.title;
export const getQueryParams = (state) => state.page.queryParams;
export const getFollowUpTemplateTitle = (state) =>
  state.page.followUpTemplate?.title;
export const getSupportedFollowUpTemplateTitles = (state) =>
  state.page.supportedFollowUpTemplateTitles;
export const getDonationAskBody = (state) => state.page.donationAskBody;
export const selectIsMobile = (state) => state?.page?.isMobile;
export const selectPostAction = (state) => state?.page?.postAction;
export const selectTemplateName = (state) => state?.page?.templateName;
export const selectLanguage = (state) => state?.page?.language?.toLowerCase();
export const selectForcedScrollToDonate = (state) =>
  state?.page?.forcedScrollToDonate;
export const selectMintHeader = (state) => state?.page?.mintHeader;
export const selectCurrentView = (state) => state?.page?.currentView;

export const selectRecurringDefault = createSelector(
  getQueryParams,
  ({ recurring_default }) => recurring_default
);

export const selectOneClick = createSelector(
  getQueryParams,
  ({ one_click, akid }) => Boolean(one_click) && Boolean(akid)
);

export const selectActionKitId = createSelector(
  getQueryParams,
  ({ akid }) => akid
);

export const selectAmountFromQuery = createSelector(
  getQueryParams,
  ({ amount }) => amount
);

export const selectPostActionImagePath = createSelector(
  selectPostAction,
  ({ image, defaultImageUrl }) => image?.large?.url || defaultImageUrl
);

export const selectPostActionCopy = createSelector(
  selectPostAction,
  ({ copy }) => copy
);

export const selectIsRecurring = createSelector(
  getQueryParams,
  ({ recurring_default }) => {
    return recurring_default == "recurring";
  }
);

export const selectShowProgressTracker = createSelector(
  selectTemplateName,
  selectForcedScrollToDonate,
  (templateName, forcedScrollToDonate) => {
    return (
      templateName === "Default: Petition And Scroll To Share Greenpeace" &&
      forcedScrollToDonate === false
    );
  }
);

// Action Types
export const SET_PAGE_ID = "page/id";
export const SET_PAGE_SLUG = "page/slug";
export const SET_PAGE_TITLE = "page/title";
export const SET_QUERY_PARAMS = "page/queryParams";
export const SET_IS_MOBILE = "page/isMobile";
export const SET_FOLLOW_UP_TEMPLATE = "page/followUpTemplate";
export const SET_POST_ACTION_IMAGE = "page/postActionImage";
export const SET_POST_ACTION_COPY = "page/postActionCopy";
export const RESET_POST_ACTION_COPY = "page/resetPostActionCopy";
export const RESET_POST_ACTION_IMAGE = "page/resetPostActionImage";
export const SET_TEMPLATE_NAME = "page/setTemplateName";
export const SET_FORCED_SCROLL_TO_DONATE_TEMPLATE =
  "page/setForcedScrollToDonate";
export const SET_MINT_HEADER = "page/setMintHeader";
export const SET_DONATION_ASK_BODY = "page/setDonationAskBody";
export const SET_CURRENT_VIEW = "page/setCurrentView";
export const SET_LANGUAGE = "page/setLanguage";
