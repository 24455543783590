import { batchActions } from "../lib/redux-utils";
import { countryChanged } from "./ActionFormReducer";
import { setConsent, setShowConsentAsk } from "./ConsentReducer";
import { seedFormValues, currencyHasChanged } from "./DonationReducer";

const initialState = {
  isPresent: false,
  details: {},
  akid: null,
  signedOut: false,
};

export const memberReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEED_MEMBER: {
      if (!action.payload) return state;
      const { member } = action.payload;
      let newState = {
        ...state,
      };

      if (member) {
        const name = member.name
          ? member.name
          : `${member.first_name} ${member.last_name}`.trim();
        const {
          id,
          email,
          postal,
          consented,
          country,
          actionkit_user_id,
          more,
          donor_status,
        } = member;
        const action_phone_number = more?.action_phone_number;
        newState = {
          ...newState,
          isPresent: true,
          details: {
            name,
            id,
            email,
            postal,
            consented,
            country,
            actionkit_user_id,
            action_phone_number,
            donor_status,
          },
        };
      }
      return newState;
    }
    case RESET_MEMBER: {
      return { ...state, isPresent: false, details: {}, signedOut: true };
    }
    case SET_MEMBER: {
      const { name, email, postal, country, action_phone_number, id } =
        action.payload;
      return {
        ...state,
        details: { name, email, postal, action_phone_number, country, id },
        isPresent: true,
        signedOut: false,
      };
    }
    default:
      return state;
  }
};

// action creators
export function setMemberData(data) {
  return (dispatch, getState) => {
    dispatch({ type: SEED_MEMBER, payload: data });
    const { country, member, currency } = data;

    const state = getState();
    const { currency: queryCurrency } = state.page.queryParams;
    let actions = [];

    if (country) {
      actions.push(countryChanged(country));
    }
    if (currency && !queryCurrency) {
      actions.push(currencyHasChanged(currency));
    }

    if (member) {
      // Updating donation reducer with form details
      const { email, postal, country, consented, more } = member;
      const user = {
        name: member.name
          ? member.name
          : `${member.first_name} ${member.last_name}`.trim(),
        email,
        postal,
        country,
        action_phone_number: more?.action_phone_number,
      };
      actions.push(
        seedFormValues({ ...user }),
        countryChanged(country),
        setConsent(consented),
        setShowConsentAsk(!(consented !== null))
      );
    }

    batchActions(dispatch, actions);
  };
}

export function resetMember() {
  return {
    type: RESET_MEMBER,
  };
}

export function setMember(payload) {
  return { type: SET_MEMBER, payload };
}

// selectors
export const getMember = (state) => state.member.details;
export const getMemberName = (state) =>
  state?.member?.isPresent ? state.member.details.name : null;
export const getMemberId = (state) =>
  state?.member?.isPresent ? state.member.details.id : null;
export const getIsMemberPresent = (state) => state.member.isPresent;
export const memberSignedOut = (state) => state.member.signedOut;

// Action Types
export const SET_MEMBER = "member/set";
export const RESET_MEMBER = "member/reset";
export const SEED_MEMBER = "member/seed";
