import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { batchedSubscribe } from "redux-batched-subscribe";
import { persistStore, persistReducer } from "redux-persist";
import { getPersistConfig } from "redux-deep-persist";
import storage from "./lib/storage-persist";

import reduxMiddleware from "./lib/redux-middleware";

import { memberReducer } from "../reducers/MemberReducer";
import { actionFormReducer } from "../reducers/ActionFormReducer";
import { consentReducer } from "../reducers/ConsentReducer";
import { donationReducer } from "../reducers/DonationReducer";
import { pageReducer } from "../reducers/PageReducer";
import { cookiePreferenceReducer } from "../reducers/CookiePreferenceReducer";

const composeEnhancers =
  (typeof window !== "undefined" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ !== undefined &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      trace: true,
      traceLimit: 25,
    })) ||
  compose;

/**
 * Function called to trigger redux
 * subscriptions, which will trigger connected components
 */
const notifyState = { notify: null };

function setNotifyState(notify) {
  notifyState.notify = notify;
}

const rootReducer = combineReducers({
  member: memberReducer,
  action_form: actionFormReducer,
  consent: consentReducer,
  donation: donationReducer,
  page: pageReducer,
  cookiePreference: cookiePreferenceReducer,
});

const persistConfig = getPersistConfig({
  key: "root",
  storage,
  whitelist: [
    "action_form.country",
    "action_form.countries_list",
    "action_form.fields.email",
    "action_form.fields.name",
    "action_form.fields.country",
    "donation.currency",
    "donation.savedPaymentMethods",
    "donation.showSavedPayments",
    "donation.merchantAccountId",
    "member.isPresent",
    "member.details",
    "consent",
    "page.postAction",
    "cookiePreference",
  ],
  rootReducer,
});

export const store =
  typeof window !== "undefined"
    ? createStore(
        persistReducer(persistConfig, rootReducer),
        composeEnhancers(
          applyMiddleware(thunk, reduxMiddleware(notifyState)),
          batchedSubscribe(setNotifyState)
        )
      )
    : createStore(
        rootReducer,
        composeEnhancers(
          applyMiddleware(thunk, reduxMiddleware(notifyState)),
          batchedSubscribe(setNotifyState)
        )
      );

export const persistor = persistStore(store);
