import { isLiveEnv } from "../../lib/environment";
import cookieCutter from "cookie-cutter";

const pixelNotLoadedError = "Facebook pixel does not exist on the page";

/**
 *
 * @param {Object[]} events - The Facebook events to be tracked.
 */
export const logEventsToFacebook = (events) => {
  events.forEach((event) => {
    const { eventName, payload } = event;
    logEventToFacebook(eventName, payload);
  });
};

export const logEventToFacebook = (eventName, ...payload) => {
  try {
    if (window.fbq) {
      if (isLiveEnv) {
        logPixelEvent(eventName, ...payload);
      } else {
        console.info("FB pixel - ", eventName, ...payload);
      }
    } else {
      console.warn(pixelNotLoadedError);
    }
  } catch (error) {
    console.error("Error sending tracking event:", error);
  }
};

export const logCustomEventToFacebook = (eventName, data) => {
  try {
    if (data && window.fbq) {
      window.fbq(
        "trackCustom",
        eventName,
        { ...data },
        { eventID: getUniqueId() }
      );
    }
  } catch (error) {
    console.error("Error sending tracking event:", error);
  }
};

const logPixelEvent = (eventName, data) => {
  if (data) {
    window.fbq("track", eventName, { ...data }, { eventID: getUniqueId() });
  }
};

export const getUniqueId = () => {
  const uniqueId = cookieCutter.get("unique_id");
  if (!uniqueId) {
    const uniqueId = generateUniqueId();
    let expirationDate = new Date();
    cookieCutter.set("unique_id", uniqueId, {
      expiration: expirationDate.setHours(expirationDate.getHours() + 1),
    });
    return uniqueId;
  }
  return uniqueId;
};

/**
 * To keep champaign format, instead of using uuid, we use crypto to genarate an alphanumeric string
 */
const generateUniqueId = () => {
  const randomNumber = crypto.getRandomValues(new BigUint64Array(1));
  return randomNumber[0].toString(36);
};

export const CONTENT_TYPE = "product";
export const PRODUCT_CATALOG_ID = 445876772724152;
